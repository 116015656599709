<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head">
        <div class="head-info">
          <el-row>
            <el-col style="text-align: left" :span="12">
              <el-page-header
                class="back"
                title="返回"
                @back="goBack"
                content="兑奖码详情"
              ></el-page-header>
            </el-col>
            <el-col style="text-align: right" :span="12">
              <el-button
                type="primary"
                icon="el-icon-check"
                @click="saveModelOnclick"
                >保存</el-button
              >
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-row>
          <el-col>
            <div class="grid-content bg-purple">
              <el-form
                class="type-form"
                :model="edit_model"
                :rules="rules"
                ref="detail_model"
                label-width="150px"
              >
                <el-form-item label="兑奖码:" prop="redeem_code">
                  <el-input v-model.trim="edit_model.redeem_code"></el-input>
                </el-form-item>
                <el-form-item label="兑奖码批次号:" prop="batch_id">
                  <el-select
                    v-model="edit_model.batch_id"
                    filterable
                    clearable
                    placeholder="请选择"
                    loading-text="加载中..."
                  >
                    <el-option
                      v-for="item in redeem_code_batch_list"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="状态:">
                  <el-select
                    v-model="edit_model.status"
                    :disabled="
                      edit_model_id &&
                      edit_model.status !== 1000 &&
                      edit_model.status !== 4000
                    "
                    clearable
                    placeholder="请选择"
                    loading-text="加载中..."
                  >
                    <el-option
                      v-for="item in $common.reedemStatusList"
                      :key="item.id"
                      :disabled="
                        edit_model_id && item.id !== 1000 && item.id !== 4000
                      "
                      :label="item.name"
                      :value="item.id"
                    ></el-option> </el-select
                  ><span style="color: #c0c4cc; margin-left: 5px"
                    >(状态变更只能在未使用和失效间相互切换)</span
                  >
                </el-form-item>
              </el-form>
            </div>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      loading: false,
      edit_model_id: "",
      edit_model: {
        id: "",
        batch_id: "",
        redeem_code: "",
        status: 1000,
      },
      redeem_code_batch_list: [],
      rules: {
        redeem_code: [
          { required: true, message: "请输入兑奖码", trigger: "blur" },
          { min: 1, message: "长度大于 1 个字符", trigger: "blur" },
        ],
        batch_id: [
          { required: true, message: "请选择批次号", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.get_redeem_code_batch_list();
  },
  mounted() {
    this.edit_model_id = this.$route.query.id;
    if (this.edit_model_id !== undefined && this.edit_model_id.length > 0) {
      // 编辑
      this.getEditModel();
    }
  },
  methods: {
    async get_redeem_code_batch_list() {
      let _me = this;
      let params = {
        key: _me.search_key,
        status: _me.page_status,
        page_size: _me.page_size,
        page_number: _me.page_number,
        sort_fields: "",
      };
      _me.loading = true;
      await _me.$common
        .httpPost("/api/redeem_code/admin/batch/find/list", params)
        .then((res) => {
          if (res.code === "1000000") {
            _me.redeem_code_batch_list =
              res.data.array === undefined ? [] : res.data.array;
            _me.page_total = res.data.total;
            _me.edit_model.batch_id =
              _me.redeem_code_batch_list &&
              _me.redeem_code_batch_list.length > 0
                ? _me.redeem_code_batch_list[0].id
                : "";
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        })
        .finally(() => {
          _me.loading = false;
        });
    },
    getEditModel() {
      let _me = this;
      _me.loading = true;
      let params = {
        id: _me.edit_model_id,
      };
      _me.$common
        .httpPost("/api/redeem_code/admin/find/id", params)
        .then((res) => {
          if (res.code === "1000000") {
            _me.edit_model = res.data;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        })
        .finally(() => {
          _me.loading = false;
        });
    },
    async saveModelOnclick() {
      if (!(await this.submitValidate())) return;
      if (this.edit_model_id !== undefined && this.edit_model_id.length > 0) {
        this.updateOneModel();
      } else {
        this.addNewModel();
      }
    },
    async submitValidate() {
      try {
        await this.$refs["detail_model"].validate();
        // 验证成功
        return true;
      } catch (err) {
        this.$message({
          message: "请检查表单",
          type: "error",
        });
        return false;
      }
    },
    addNewModel() {
      let _me = this;
      let params = {
        batch_id: _me.edit_model.batch_id,
        redeem_code: _me.edit_model.redeem_code,
        status: _me.edit_model.status,
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/redeem_code/admin/add", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("内容添加成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    updateOneModel() {
      let _me = this;
      let params = {
        id: _me.edit_model.id,
        batch_id: _me.edit_model.batch_id,
        redeem_code: _me.edit_model.redeem_code,
        status: _me.edit_model.status,
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/redeem_code/admin/update", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("内容保存成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    goBack() {
      // 返回上一级路由
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.type-form {
  margin: 0;
  max-width: 640px;
}
.back {
  /* padding: 20px 0; */
  margin-top: 0;
}
</style>
